.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 40px 60px;
  background-color: rgba(var(--color-green-dark));
  display: flex;
  transform: translate(-100%, -100%);
  opacity: 1;
  transition: transform 0.6s 0s cubic-bezier(.22,.68,0,1), border 0.5s 0.35s cubic-bezier(.22,.68,0,1);
  z-index: 10;
  border-radius: 0% 0% 70% 0% / 28% 59% 41% 43%;
  border-bottom: 0px solid rgba(var(--color-theme));
}

.mobile-nav-white-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(var(--color-white), 0.5);
  backdrop-filter: blur(5px);
  z-index: 0;
  transform: translateX(100%);
  transition: all 0.6s 0.3s cubic-bezier(.22,.68,0,1);
}

.mobile-nav-white-filter.active {
  transition-delay: .3s;
  transform: translateX(0);
}

.mobile-nav.active {
  transform: translate(0, 0);
  border-bottom: 20px solid rgba(var(--color-theme));
  transition: transform 0.6s 0.35s cubic-bezier(.22,.68,0,1), border 0.5s 0.8s cubic-bezier(.22,.68,0,1);
} 


.mobile-nav a {
  font-size: 2rem;
  color: rgba(var(--color-white));
  line-height: 0;
}

.mobile-nav > ul > li {
  opacity: 0;
  transition: all 0.6s .0s cubic-bezier(.22,.68,0,1.31);
  margin-bottom: 30px;
}

.mobile-nav.active > ul > li {
  opacity: 1;
  transition-delay: 0.55s;
}


@media screen and (max-width: 475px) {
  .mobile-nav a {
    font-size: 1.8rem;
  }

}
