footer {
  position: relative;
  overflow: hidden;
}

footer p {
  font-size: 14px;
}

form.newsletter-signup {
  display: flex;
  flex-direction: column;
}

form.newsletter-signup input[type="email"] {
  border: 2px solid rgb(var(--color-grey));
  margin-right: 10px;
}

.newsletter-signup label {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.newsletter-signup label a {
  font-size: 13px;
}

.footer-links li:not(:last-child) {
  margin-bottom: 18px;
}

.footer-links li a {
  text-decoration: none;
  transition: all .25s var(--cubic);
}

.footer-links li:first-child a {
  font-size: 20px;
  font-weight: 600;
  color: rgba(var(--color-black));
}

.footer-links li a:hover, .newsletter-signup label a:hover {
  color: rgba(var(--color-theme));
}

.bottom-footer {
  border-top: 1px solid rgba(var(--color-grey));
  padding: 30px 0;
  margin-top: 30px;
  position: relative;
}
