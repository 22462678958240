.modal {
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(var(--color-green-dark));
  color: rgba(var(--color-white));
  z-index: 101;
  padding: 60px 50px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close {
  color: rgba(var(--color-white));
  font-size: 40px;
  position: absolute;
  top: 20px;
  right: 30px;
  line-height: 1;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--color-white), 0.1);
  z-index: 100;
  backdrop-filter: blur(7px);
  overflow-y: auto;
}

/*place recaptcha badge in alignment with modal padding*/
.modal .grecaptcha-badge {
  bottom: 60px !important;
}

@media screen and (max-width: 640px){
  .modal {
    width: 100%;
    padding: 60px 30px;
    height: 100vh;
    overflow-y: auto;
    justify-content: flex-start;
    top: 0;
    transform: translate(-50%, 0);
  }
  .modal .grecaptcha-badge {
    position: absolute !important;
    bottom: -16px !important; 
  }
}
@media screen and (max-height: 745px) {
  .modal {

    top: 0;
    transform: translate(-50%, 0);
  }
}

/* @media screen and (max-width: 422px){
  .modal .grecaptcha-badge {
    bottom: -80px !important;
  }
}

@media screen and (max-width: 383px){
  .modal .grecaptcha-badge {
    bottom: -180px !important;
  }
} */
