.influencers-grid {
  display: grid;
  grid-gap: 40px;
}

.influencers-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  border-radius: 12px;
  box-shadow: 0 14px 24px 0 rgba(var(--color-black), 0.12);
  width: 100%;
  overflow: hidden;
  transition: all .25s var(--cubic);
}

/* .influencers-item:not(:last-of-type) {
  margin-bottom: 30px;
} */

.influencers-item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.influencers-item img {
  max-width: 75px;
  width: 100%;
  aspect-ratio: 4/4;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  transition: all .5s var(--cubic);
}

.influencers-item:hover  {
  box-shadow: 0 14px 24px 0 rgba(var(--color-black), 0.08);
}

.influencers-item:hover img {
  box-shadow: 
  1px 1px 1px 0 rgba(var(--color-theme), 0.4),
  2px 2px 1px 0 rgba(var(--color-theme), 0.4),
  3px 3px 1px 0 rgba(var(--color-theme), 0.4),
  4px 4px 1px 0 rgba(var(--color-theme), 0.4);
}

.influencers-item-socials  {
  display: flex;
  justify-content: center;
  width: 100%;
}

.influencers-item-socials i {
  font-size: 1.75rem;
  font-weight: 600 !important;
}

.influencers-item-socials a {
  display: flex;
  justify-content: center;
  padding: 0 15px;
}

.influencers-item-socials a:not(:last-of-type) {
  border-right: 1px solid rgba(var(--color-black), 0.2);
}
