form.book-demo {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

form.book-demo input[type="text"], form.book-demo input[type="email"], form.book-demo textarea {
  width: 100%;
}

form.book-demo .form-group:nth-child(3), form.book-demo .form-group:nth-child(4)  {
  width: 48%;
}

form.book-demo label {
  display: flex;
  align-items: center;
}

form.book-demo label p {
  font-size: 13px;
}

@media screen and (max-width: 640px){
  /*
   Make the button wrapper absolute to escape modal padding.
   add 30px to width to send the captcha to the right position
  */
  form.book-demo .button-wrapper {
    width: calc(100% + 30px);
    position: absolute;
    bottom: -40px;
  }
}
