.btn {
  padding: 6px 25px;
  border: 2px solid rgb(var(--color-theme-contrast));
  background-color: rgb(var(--color-white));
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color .25s var(--cubic);
  transition-delay: .2s;
}

.btn span {
  position: relative;
  z-index: 1;
  color: rgb(var(--color-theme-contrast));
  transition: color .25s var(--cubic);
  transition-delay: .2s;
  font-weight: 500;
}

.btn::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 0;
  width: 100%;
  background-color: rgb(var(--color-theme));
  z-index: 0;
  transition: all .2s 0s var(--cubic);
}

.btn:hover {
  background-color: rgba(var(--color-theme), .7);
  transition-delay: 0s;
}

.btn:hover span {
  transition-delay: 0s;
  color: rgb(var(--color-theme-contrast));
}

.btn:hover::before {
  height: 110%;
  transition-delay: .25s;
  transition-duration: .5s;
}

.btn.filled span {
  color: rgb(var(--color-theme-contrast));
}

.btn.filled  {
  background-color: rgb(var(--color-theme));
  border: 2px solid rgb(var(--color-theme));

} 

.btn.filled:hover {
  background-color: rgba(var(--color-theme), 0);
}

.btn.white {
  background-color: rgb(var(--color-white));
  border-color: rgb(var(--color-white));
}

.btn.white span {
  color: rgb(var(--color-grey-dark));
}

.btn.white:hover span {
  color: rgb(var(--color-grey-dark));
}

.btn.white:hover {
  background-color: rgba(var(--color-white), .15);
}

.btn.white::before {
  background-color: rgb(var(--color-white));
}

.btn.white:hover::before {
  background-color: rgb(var(--color-white));
}
