.cookie-consent {
  padding: 30px;
  background-color: rgba(var(--color-white), 0.9);
  border-radius: 5px;
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  z-index: 51;
  backdrop-filter: blur(7px);
  max-width: 500px;
  box-shadow: 0 14px 24px 0 rgba(var(--color-black), 0.12);
}

@media screen and (max-width: 640px){
  .cookie-consent {
    width: calc(100% - 40px);
    max-width: 100%;
  }
}
