.text-block {
  width: 100%;
}

.text-block.sm {
  max-width: 350px;
}

.text-block.md {
  max-width: 470px
}

.text-block.lg {
  max-width: 550px;
}
