.social-media {
  display: flex;
  margin-top: 30px;
}

.social-media div {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgb(var(--color-green-dark));
  transition: all .25s var(--cubic);
  display: flex;
  justify-content: center;
  align-items: center;;
}

.social-media div:hover {
  background-color: rgb(var(--color-theme));
}

.social-media div:hover i {
  transform:  rotateY(360deg);
}

.social-media div i {
  color: rgb(var(--color-white));
  transition: all .25s var(--cubic);
  line-height: 0;
}

.social-media a {
  text-decoration: none;
}

.social-media a:not(:last-child) {
  margin-right: 10px;
}
