section {
  padding: 30px 0;
}

section.background {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

section.fullscreen {
  min-height: 100vh;
}

section.green  {
  background-color: rgba(var(--color-green-dark));
  color:rgba(var(--color-white));
} 
