
.page-cta {
  background-color: rgba(var(--color-green-light));
}
.page-home-why_us {
  background-color: rgba(var(--color-light-grey));
}
.page-home-cover-video {
  background-color: rgba(var(--color-green-light));
}
.page-home-customer-success {
  background-color: rgba(var(--color-theme-marker-light));
  padding-top: 40px;
}
