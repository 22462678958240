
.policies p, 
.policies span,  
.policies ul li {
  font-size: 18px;
}

.policies ul {
  padding: 0 20px;
}

.policies ul ul {
  list-style-type: disc;
  padding: 0 40px;
}



