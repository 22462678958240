i.icon { display: inline-flex;}

i.icon.sm svg { width: 24px; height: 24px; }
i.icon svg { height: 40px; width: 40px; }
i.icon.lg svg { height: 52px; width: 52px; }
i.icon.lg.background, i.icon.lg.white-background { padding: 26px; } 

i.icon.background, 
i.icon.white-background {
  border-radius: 50%;
  padding: 20px;
}

i.icon.background {
  background-color: rgb(var(--color-theme-marker-light));
}

i.icon.white-background {
  background-color: rgb(var(--color-white));
  box-shadow: 0 14px 24px 0 rgba(var(--color-theme), 0.12);
}

i.icon svg path,
i.icon.background svg path,
i.icon svg polyline,
i.icon.background svg polyline,
i.icon svg polygon,
i.icon.background svg polygon {
  stroke:rgb(var(--color-theme-marker));
}

i.icon.white-background svg path,
i.icon.white-background svg polyline,
i.icon.white-background svg polygon {
  stroke:rgb(var(--color-theme));
}

i.icon.background.useFill svg path,
i.icon.background.useFill svg polyline,
i.icon.background.useFill svg polygon {
  fill:rgb(var(--color-theme-marker));
  stroke:rgb(var(--color-theme-marker));
}

i.icon.white-background.useFill svg path,
i.icon.white-background.useFill svg polyline,
i.icon.white-background.useFill svg polygon {
  fill:rgb(var(--color-theme));
  stroke:rgb(var(--color-theme));
}


