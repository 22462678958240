.hamburger-wrapper {
  width: 30px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 99;
}

.hamburger {
  width: 100%;
  height: 100%;
  transition: all .25s .5s linear;
  position: relative;
}

.hamburger span {
  width: 100%;
  height: 2px;
  background-color: rgba(var(--color-white));
  transition: all .15s 0s var(--cubic-step);
  position: absolute;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}

.hamburger span:nth-child(3) {
  top: 90%;
}

/* Active */
.hamburger.active  {
  height: 2px;
  transition: all .1s .25s linear;
} 

.hamburger.active span {
  background-color: rgba(var(--color-theme));
  transition: top .15s var(--cubic-step), transform .15s .5s var(--cubic-step), color .15s linear;
}

.hamburger.active span:nth-child(1) {
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}

.hamburger.active span:nth-child(2) {
  transform: translateX(50px);
  opacity: 0;
}

.hamburger.active span:nth-child(3) {
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
}

header.scrolled .hamburger span {
  background-color: rgba(var(--color-theme));
}
