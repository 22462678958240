.language-switcher {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: rgb(var(--color-green-dark));;
  border-radius: 5px;
  backdrop-filter: blur(10px);
  transition: all .25s var(--cubic);
  box-shadow: 0px 1px 7px 2px rgba(var(--color-black), 0.1);
}

.language-switcher:hover {
  box-shadow: 0px 1px 7px 2px rgba(var(--color-black), 0.2);
}

.language-switcher:hover ul li {
  display: flex;
}

.language-switcher ul {
  display: flex;
  flex-direction: column;
  width: 73px;
}

.language-switcher ul li  {
  padding: 5px 10px;
  display: none;
  cursor: pointer;
  transition: all .25s var(--cubic);
  align-items: center;
  line-height: 1;
  color: rgba(var(--color-white));
}

.language-switcher ul li a {
  color:rgba(var(--color-white), 0.8)
}

.language-switcher ul li span {
  margin-right: 5px;
}

.language-switcher ul li a:hover {
  color: rgba(var(--color-white), 1) !important;
}

.language-switcher ul li:not(:first-child) {
  border-top: 1px solid rgba(var(--color-white), 0.15);
}

.language-switcher ul li:first-child {
  display: flex;
  font-weight: 600;
}

/* If language switcher in header */
header.scrolled ul li:hover {
  color: rgba(var(--color-black), 1) !important;
}

header.scrolled .language-switcher ul li {
  color: rgba(var(--color-black));
}

header.scrolled .language-switcher ul li:not(:first-child) {
  border-top: 1px solid rgba(var(--color-black), 0.15);
  color:rgba(var(--color-black), 0.6)
}

header.scrolled .language-switcher {
  top: -20px;
  backdrop-filter: blur(0);
  background-color: rgba(var(--color-white), 1);
}
