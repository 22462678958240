.solutions_text-list ul li::before  {
  content: "\f00c";
  color:white;
  font-family: "FontAwesome";
  margin-right: 10px;
  font-size: 10px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgb(var(--color-theme));
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  align-self: center;
}

.company-form .contact-us label {
  display: flex;
  width:100%;
}

.company-form .company-info {
  border-bottom: 1px solid rgba(var(--color-white), .4);
}

.company-form .contact-us label p {
  font-size: 13px;
}
.company-form .contact-us input[type="text"], .company-form .contact-us input[type="email"]{
  width:48%;
}

.company-form .grecaptcha-badge {
  position: absolute !important;

}

.solutions-benefits-numbers {
  font-size: 60px;
  font-family: 'Metric', 'Open Sans', sans-serif;
  font-weight: 500;
  color:rgba(var(--color-theme));
  line-height: 70px;
}


@media screen and (max-width: 640px){
  /*
   Make the button wrapper absolute to escape modal padding.
   add 30px to width to send the captcha to the right position
  */
  .company-form .contact-us .button-wrapper {
    width: calc(100% + 30px);
    position: absolute;
    bottom: -20px;
  }
}
