header {
  padding:0;
  position: fixed;
  width: 100%;
  transition: all .5s var(--cubic);
  z-index: 99;
  background-color: rgba(var(--color-white), 0);
}

header nav > ul.main-nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

header nav > ul.main-nav > li:not(:last-child) {
  margin-right: 40px;
}

@media screen and (max-width: 1440px){
  header nav > ul.main-nav > li:not(:last-child) {
    margin-right: 30px;
  }
}

header nav > ul.main-nav > li > a {
  text-decoration: none;
  color: rgb(var(--color-white));
  transition: color 1.5s var(--cubic);
  position: relative;
  z-index: 1;
}

header nav > ul.main-nav > li {
  padding: 10px 0;
}

header nav > ul.main-nav > li > a::before, 
header nav > ul.main-nav > li > a::after {
  content: '';
  position: absolute;
  bottom: -5px;
  width: 0%;
  height: 1px;
  background-color: rgba(var(--color-white));
  transition: width .25s var(--cubic-step), background-color .5s .1s linear;
  z-index: -2;
}

header nav > ul.main-nav > li > a::before {
  left: 0;
}

header nav > ul.main-nav > li > a::after {
  right: 0;
}

header nav > ul.main-nav > li > a.active {
  font-weight: 600;
}

header nav > ul.main-nav > li > a:hover::before, 
header nav > ul.main-nav > li > a:hover::after, 
header nav > ul.main-nav > li > a.active::before,
header nav > ul.main-nav > li > a.active::after {
  width: 50%;
  background-color: rgba(var(--color-theme));
}

header.scrolled {
  background-color: rgba(var(--color-white), 0.8);
  box-shadow: 0 3px 12px 0 rgba(var(--color-black), .07);
  backdrop-filter: blur(10px);
}

header.scrolled nav > ul.main-nav > li > a {
  color: rgb(var(--color-black));
}

header.scrolled nav > ul.main-nav > li > a.active, 
header.scrolled nav > ul.main-nav > li > a:hover {
  color: rgb(var(--color-theme-contrast)) !important;
}
header.scrolled nav > ul.main-nav > li > a.active::before, 
header.scrolled nav > ul.main-nav > li > a:active::after {
  background-color: rgb(var(--color-theme-contrast)) !important;
}

header.scrolled nav > ul.main-nav > li > a::before, 
header.scrolled nav > ul.main-nav > li > a::after {
  background-color: rgba(var(--color-black));
} 

/* submenu */

header nav > ul.main-nav > li.dropdown > .sub-nav-wrapper {
  background-color: rgba(var(--color-white));
  width: 100%;
  position: absolute;
  left: 0;
  top: 42px;
  display: none;
}

header nav > ul.main-nav > li.dropdown:hover .sub-nav-wrapper  {
  display: block;
}

header nav > ul.main-nav > li.dropdown > .sub-nav-wrapper > ul.sub-nav {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav .submenu-item {
  position: relative;
}

.mobile-nav .submenu-item:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translate(-100%, -50%);
  width: 15px;
  height: 5px;
  background-color: rgba(var(--color-theme));
  border-radius: 50px;
}
