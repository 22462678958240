.snackbar {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: rgba(var(--color-green-dark));
  z-index: 999;
  border-radius: 5px;
  box-shadow: 0 14px 24px 0 rgba(var(--color-white), 0.12);
}

.snackbar p {
  color: rgba(var(--color-white));
  font-size: 1rem;
}

.snackbar div {
  display: flex;
  align-items: center;
}

.snackbar i {
  color: rgb(var(--color-white));
  margin-right: 10px;
}
