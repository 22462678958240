.testimonial {
  position: relative;
  z-index: 1;
}

.testimonial::after {
  content: '"';
  font-family: 'Metric', 'Open Sans', sans-serif;
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25rem;
  line-height: 0;
  opacity: .15;
  color: rgba(var(--color-theme));
  z-index: -1;
  pointer-events: none;
}
