.text-list ul li { position: relative; }
.text-list ul li::before  {
  content: "\f00c";
  font-family: "FontAwesome";
  display: inline-block;
  margin-right: 10px;
  font-size: 9px;
  line-height: 16px;
  vertical-align: middle;
  /* background-color: #2d545f; */
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
}
