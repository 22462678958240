
.content-wide {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.content-standard {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.content-small {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
}
.content-xsmall {
  max-width: 565px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1440px){
  .content-wide {
    max-width: 1200px;
  }

  .content-standard {
    max-width: 1024px;
  }
}
