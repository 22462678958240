@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-theme: 16, 214, 216; /*  coursio */
  --color-white: 255, 255, 255;
  --color-black: 0, 0, 0;
  --color-light-grey: 242, 242, 242;
  --color-grey: 112, 119, 120;
  --color-grey-dark: 65 , 73, 73;
  --color-green-dark: 14, 108, 128; /*  coursio primary */
  --color-green-light: 207, 226, 230; /*  coursio primary light */
  --color-theme-contrast: 59, 59, 59;
  --color-theme-marker-light: 227, 218, 255;
  --color-theme-marker: 42, 0, 161;
  --cubic: cubic-bezier(0.15, 0.91, 0.58, 1);
  --cubic-step: cubic-bezier(1, 0.18, 0.02, 0.6);
}

@font-face {
  font-family: 'Metric';
  src: url(./assets/fonts/MetricWeb-Regular.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: 'Metric';
  src: url(./assets/fonts/MetricWeb-Medium.woff) format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Metric';
  src: url(./assets/fonts/MetricWeb-Semibold.woff) format("woff");
  font-weight: 600;
  font-display: swap;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Metric', 'Open Sans', sans-serif;
  color: rgba(var(--color-grey));
  -webkit-font-smoothing: antialiased;
  accent-color: rgb(var(--color-theme));
}

#root {
  position: relative;
}

html, html:after, html:before, * {
  box-sizing: border-box;
}

h1 {
  font-size: 40px;
  font-family: 'Metric', 'Open Sans', sans-serif;
  font-weight: 500;
  color:white;
  line-height: 44px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
}

h3 {
  font-size: 22px;
  line-height: 28px;
}

h4 {
  font-size: 18px;
}

h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: rgba(var(--color-grey-dark));

}

.text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 {
  color: white;
}

p {
  font-size: 20px;
  line-height: 28px;

}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a, p a {
  color: rgba(var(--color-grey));
  text-decoration: none;
  transition: all .25s var(--cubic);
}

a:hover, a.active, p a:hover {
  color: rgba(var(--color-theme)) !important;
}

form input[type="text"], form input[type="email"], form textarea {
  padding: 6px 7px;
  border-radius: 5px;
  color: rgb(var(--color-black));
}

form input[type="checkbox"] {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
}

.color-theme {
  color: rgb(var(--color-theme));
}

.color-theme-contrast {
  color: rgb(var(--color-theme-contrast));
}

video, iframe {
  aspect-ratio: 16 / 9;
  width:100%;
}

.bg-blue-blur {
  background-color: rgba(var(--color-green-dark), 0.3);
  backdrop-filter: blur(7px);
}

img.custom-size_img {
  width: 8rem;
}
img.customer-logo {
  max-width: 14rem;
  max-height: 2rem;
  align-self: flex-end;
}
